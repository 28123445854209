<template>
  <v-card flat v-if="bonusSelected">
    <v-toolbar color="teal" dark>
      <v-toolbar-title>Lançar Valores</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="$emit('close-dialog')"
        ><v-icon arge>mdi-close-circle</v-icon></v-btn
      >
    </v-toolbar>
    <v-card-title></v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <div v-for="(fieldsValue, i) in fieldsValues" :key="i">
          <v-container>
            <v-row class="mb-n6">
              <v-col cols="12" md="3">
                <v-text-field
                  dense
                  placeholder="Ex: Até 10 vendas"
                  :label="fieldsValue.labelTitle"
                  v-model="fieldsValue.name"
                  size="12"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  dense
                  placeholder="Ex: 1"
                  :label="fieldsValue.labelMin"
                  v-model.number="fieldsValue.min"
                  counter="4"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  dense
                  placeholder="Ex: 10"
                  :label="fieldsValue.labelMax"
                  v-model.number="fieldsValue.max"
                  counter="4"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  prefix="R$"
                  dense
                  outlined
                  placeholder="10,00"
                  :label="fieldsValue.labelValue"
                  v-model="fieldsValue.unit"
                  v-currency="{
                    currency: null,
                    locale: 'pt-BR',
                    distractionFree: false,
                    valueAsInteger: false,
                    autoDecimalMode: true,
                    valueRange: { min: 0, max: 1000 },
                    allowNegative: false,
                  }"
                  counter="10"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-btn @click="remove(i)" class="error"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </div>
        <div class="text-right col-md-11 col-12">
          <v-btn color="primary" @click="add"><v-icon>mdi-plus</v-icon></v-btn>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions class="pl-9">
      <v-btn color="success" outlined large :disabled="!valid" @click="formater"
        >Salvar Valores</v-btn
      >
    </v-card-actions
    >
    
  </v-card>
</template>

<script>
import { CurrencyDirective, parse } from "vue-currency-input";
export default {
  directives: {
    currency: CurrencyDirective,
  },
  props: ["bonusSelected"],

  data: () => {
    return {
      valuers: [],
      valid: true,
      fieldsValues: [],
      bonus: {
        name: "",
        description: "",
        type: "",
      },
      types: ["ESCALA", "PORCENTAGEM"],
      nameRules: [(v) => !!v || "Esse campo é obrigatorio!!! "],
      Rules: [(v) => !!v || "Selecione uma regra para continuar"],
      textarea: [(v) => v.length <= 100 || "Maximo de 100 caracteres"],
    };
  },
  methods: {
    formater() {
      let options = {
        currency: null,
        locale: "pt-BR",
        distractionFree: false,
        valueAsInteger: false,
        autoDecimalMode: true,
        valueRange: { min: 0 },
        allowNegative: false,
      };
      this.valuers = this.fieldsValues;
      this.valuers.forEach((e) => {
        e.unit = parse(e.unit, options);
      });
      this.create();
    },
    async create() {
      try {
        let data = {
        bonusId: this.bonusSelected.id,
        values: this.valuers,
      };
     await this.$http.post("/valuers/import", data);
             this.$toast.success("Valores cadastrados com sucesso!");
             this.$emit('close-dialog')


      } catch (error) {
        if(error){
           this.$toast.error("Error ao inserir valores!");
        }
          

      }
      
     
    },
    add() {
      this.fieldsValues.push({
        valueTitle: "",
        labelTitle: "Nome da regra",
        labelMin: "Qtd Mínima",
        labelMax: "Qtd Maxima",
        labelValue: "Valor de bonificação",
      });
    },
    remove(index) {
      this.fieldsValues.splice(index, 1);
    },
    async createType() {
      const result = await this.$http.post("/bonus", this.bonus);
      if (result.status == 201) {
        this.$toast.success("Produto Cadastrado com Sucesso!");
        this.$emit("update-list");
      } else {
        this.$toast.error("Falha ao Cadastrar novo Produto!");
      }
    },
  },
};
</script>

<style>
</style>