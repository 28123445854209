<template>
    
<v-data-table
    :headers="headers"
    :items="bonusValues"
    :items-per-page="5"
    class="elevation-1"
  >
  
    <template v-slot:top>
          <v-toolbar dense flat class="teal" dark>
            <v-toolbar-title> Listagem de bonus </v-toolbar-title>

          </v-toolbar>
          <v-divider></v-divider>
        </template></v-data-table>
  
</template>

<script>
export default {
  name: "bonusValuers",
  props: ["bonusSelected"],

  data: () => {
    return {
      bonusValues: [],
      bonus: null,
      headers: [
        { text: "Nome", value: "nome" },
        { text: "QTD Minima", value: "min" },
        { text: "QTD Maxima", value: "max" },
        { text: "Valor de bonus", value: "unit" },
      ],
    };
  },
  created() {
    this.getBonusValues();
  },
  methods: {
    async getBonusValues() {
      try {
        this.bonus = this.bonusSelected.id;
        const { data } = await this.$http.get(`/valuers/bonus/${this.bonus}`);
        this.bonusValues = data.data;
      } catch (error) {
        this.$toast.error("Não foi possivel carregar os valores.");
      }
    },
  },
};
</script>

<style>
</style>