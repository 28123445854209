<template>
  <v-card flat v-if="bonus">
    <v-toolbar color="teal" dark>
      <v-toolbar-title>Editar Tipo da Bonificação</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="$emit('close-dialog')"
        ><v-icon arge>mdi-close-circle</v-icon></v-btn
      >
    </v-toolbar>
    <v-card-title></v-card-title>
 <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-text-field
          name="name"
          label="Nome do Tipo"
          placeholder="Informe o nome do tipo da bonificação"
          v-model="bonus.name"
          :rules="nameRules"
          required
        />
        <v-select
          :items="types"
          v-model="bonus.type"
          
          :rules="Rules"
          label="Regra"
          placeholder="Informe a regra para o tipo da bonificacão"
        ></v-select>
                <v-textarea
                label="Descrição"
          placeholder="Ex: Bonificação para Rádio AC 5.8"
          textarea
          filled
          :rules="textarea"
          counter="100"
          v-model="bonus.description"
                >

                </v-textarea>
        
      </v-form>
    </v-card-text>
    <v-card-actions class="pl-6">
      <v-btn
        color="success"
        outlined
        large
        :disabled="!valid"
        @click="updateBonus()"
        >Atualizar Tipo</v-btn
      >
    </v-card-actions>
 
  </v-card>
</template>

<script>
export default {
  props: ["bonusSelected"],
  data: () => {
    return {
      valid: true,
      bonus: {
        id: null,
        name: "",
        description: "",
        type: "",
      },
      types: ["ESCALA", "PORCENTAGEM"],
      nameRules: [v => !!v || "Esse campo é obrigatorio!!! "],
      Rules: [v => !!v || "Selecione uma regra para continuar"],
      textarea: [v => v.length <= 100 || 'Maximo de 100 caracteres'],
    };
  },
  created() {
    this.setDataBonus();
  },
  methods: {
    async updateBonus() {
      const result = await this.$http.put("/bonus", this.bonus);
      if (result.status == 200) {
        this.$toast.success("Tipo de bonificação Atualizado com Sucesso!");
        this.$emit("update-list");
      } else {
        this.$toast.error("Falha ao atualizar novo Tipo de bonificação!");
      }
    },
    setDataBonus() {
      this.bonus.id = this.bonusSelected.id;
      this.bonus.name = this.bonusSelected.nome;
      this.bonus.description = this.bonusSelected.descricao;
      this.bonus.type = this.bonusSelected.tipo;
    },
  },
};
</script>

<style>
</style>