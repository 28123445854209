<template>
  <v-container>
    <v-row class="justify-center">
      <v-data-table
        v-if="bonusTypes"
        :headers="header_bonus"
        :items="bonusTypes"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar dense flat class="teal" dark>
            <v-toolbar-title> Tipos de bonificações </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color=""
                  v-on="on"
                  v-bind="attrs"
                  class="mt-2 mb-2"
                  icon
                  x-large
                  @click="dialogNewBonus = true"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              Lançar novo tipo
            </v-tooltip>
          </v-toolbar>
          <v-divider></v-divider>
        </template>

        <template v-slot:item.deleted="{ item }">
          <v-icon :color="getColor(item.deleted)"
            >mdi-checkbox-marked-circle</v-icon
          >
        </template>

        <template v-slot:item.manipula_meta="{ item }">
          <v-icon :color="setColarMeta(item.manipula_meta)"
            >mdi-checkbox-marked-circle</v-icon
          >
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn color="primary" icon text @click="dialogListValuers = true,  loadBonus(item)"><v-icon>mdi-eye</v-icon></v-btn>
          <v-btn
            text
            icon
            color="primary lighten-1"
            @click="(dialogNewValuers = true), loadBonus(item)"
          >
<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M15 15V17H18V20H20V17H23V15H20V12H18V15M14.97 11.61C14.85 10.28 13.59 8.97 12 9C10.3 9.03 9 10.3 9 12C9 13.7 10.3 14.94 12 15C12.38 15 12.77 14.92 13.14 14.77C13.41 13.67 13.86 12.63 14.97 11.61M13 16H7C7 14.9 6.11 14 5 14V10C6.11 10 7 9.11 7 8H17C17 9.11 17.9 10 19 10V10.06C19.67 10.06 20.34 10.18 21 10.4V6H3V18H13.32C13.1 17.33 13 16.66 13 16Z" />
</svg>
          </v-btn>
          <v-btn
            text
            icon
            color="teal lighten-1"
            @click="(dialogEditBonus = true), loadBonus(item)"
          >
            <v-icon> mdi-square-edit-outline </v-icon>
          </v-btn>
          <v-btn
            text
            icon
            color="red lighten-2"
            @click="(dialogRemoveBonus = true), loadBonus(item)"
          >
            <v-icon> mdi-trash-can-outline </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-row>
    <v-divider></v-divider>
    <v-dialog
      class="justify-center"
      v-model="dialogNewBonus"
      overlay-opacity="0.9"
      max-width="50%"
      transition="dialog-transition"
    >
      <newTypeBonus
        v-on:close-dialog="closeNewBonus"
        v-on:update-list="refreshList"
        v-if="dialogNewBonus"
      />
    </v-dialog>

    <v-dialog
      class="justify-center"
      v-model="dialogEditBonus"
      overlay-opacity="0.9"
      max-width="50%"
      transition="dialog-transition"
    >
      <editBonus
        :bonusSelected="bonus"
        v-on:close-dialog="closeEditBonus"
        v-on:update-list="refreshList"
        v-if="dialogEditBonus"
      />
    </v-dialog>

    <v-dialog
      class="justify-center"
      v-model="dialogRemoveBonus"
      overlay-opacity="0.3"
      max-width="400px"
      transition="dialog-transition"
    >
      <removeBonus
        :bonusSelected="bonus"
        v-on:close-dialog="closeRemoveBonus"
        v-on:update-list="refreshList"
        v-if="dialogRemoveBonus"
      />
    </v-dialog>
        <v-dialog

      class="justify-center"
      v-model="dialogNewValuers"
      overlay-opacity="0.3"
      max-width="60%"
      transition="dialog-transition"
      v-if="dialogNewValuers"

    >
      <newValuesBonus
          :bonusSelected="bonus"
        v-on:close-dialog="closeValuesBonus"

      />

    </v-dialog>
        <v-dialog

      class="justify-center"
      v-model="dialogListValuers"
      overlay-opacity="0.3"
      max-width="60%"
      transition="dialog-transition"
      v-if="dialogListValuers"

    >
      <listValuesBonus
          :bonusSelected="bonus"

      />

    </v-dialog>
  </v-container>
</template>

<script>
import newTypeBonus from "./newTypeBonus";
import newValuesBonus from "./newValuesBonus";
import removeBonus from "./removeBonus";
import editBonus from "./editBonus";
import listValuesBonus from "./listValuesBonus";

export default {
  components: {
    newTypeBonus,
    removeBonus,
    newValuesBonus,
    editBonus,
    listValuesBonus
    
  },
  data: () => {
    return {
      bonus: null,
      bonusTypes: [],
      header_bonus: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome" },
        { text: "Descricão", value: "descricao" },
        { text: "Tipo", value: "tipo" },
        { text: "Ativo", value: "deleted" },
        { text: "Ações", value: "actions" },
      ],
      dialogNewBonus: false,
      dialogEditBonus: false,
      dialogRemoveBonus: false,
      dialogNewValuers: false,
      dialogListValuers: false
    };
  },
  mounted() {
    this.getBonus();
  },
  methods: {
    async getBonus() {
      try {
        const { data } = await this.$http.get("/bonus");
        this.bonusTypes = data.data;
      } catch (error) {
        this.$toast.error("Não foi possivel carregar os produtos.");
      }
    },
    loadBonus(bonus) {
      this.bonus = bonus;
    },
    refreshList() {
      this.dialogNewBonus = false;
      this.dialogEditBonus = false;
      this.dialogRemoveBonus = false;
      this.getBonus();
    },
    getColor(status) {
      if (!status) return "success";
      else return "error";
    },
    setColarMeta(status) {
      if (status) return "success";
      else return "error";
    },
    closeNewBonus() {
      this.dialogNewBonus = false;
    },
    closeEditBonus() {
      this.dialogEditBonus = false;
    },
    closeValuesBonus() {
      this.dialogNewValuers = false
    },
    closeRemoveBonus() {
      this.dialogRemoveBonus = false;
    },
  },
};
</script>

<style>
</style>