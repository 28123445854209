<template>
  <v-card flat v-if="bonusSelected">
    <v-toolbar color="teal" dark>
      <v-toolbar-title>Remover Tipo de Bonificação</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="$emit('close-dialog')"
        ><v-icon arge>mdi-close-circle</v-icon></v-btn
      >
    </v-toolbar>
    <v-card-title> Você deseja remover este tipo?</v-card-title>
    <v-card-text>
      <v-alert dense outlined type="error" class="mt-2">
        Atenção! Ao confirmar a alteração,
        <strong> o tipo será removido</strong>!
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red" text v-on:click="$emit('close-dialog')">
        <v-icon arge>mdi-close</v-icon> Cancelar</v-btn
      >
      <v-btn color="success" text @click="removeBonus()">
        <v-icon arge>mdi-check</v-icon> Confirmar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["bonusSelected"],

  data: () => {
    return {
      bonus: null,
    };
  },
 created(){
          this.setDataBonus();

 },
  methods: {
    setDataBonus() {
      this.bonus = this.bonusSelected.id;
    },
    async removeBonus() {
      const result = await this.$http.delete(`/bonus/${this.bonus}`);
      if (result.status == 200) {
        this.$toast.success("Tipo de bonificação Removido com Sucesso!");
        this.$emit("update-list");
      } else {
        this.$toast.error("Falha ao Remover Tipo de bonificação!");
      }
    }
  }
};
</script>

<style>
</style>